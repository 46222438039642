<template>
  <div>
    <v-autocomplete
      v-model="localValue"
      :items="contacts"
      :loading="fetching_contacts"
      :search-input.sync="search"
      :append-outer-icon="
        $store.getters['auth/getScope']('contacts.add')
          ? 'mdi-account-plus'
          : null
      "
      clearable
      v-bind="$attrs"
      return-object
      @click:append-outer="openContactDialog()"
    ></v-autocomplete>

    <v-card v-if="localValue.id && !hideDetailContact" outlined>
      <v-list-item>
        <v-list-item-avatar color="grey lighten-2">
          <v-icon
            v-text="localValue.type == 'F' ? 'mdi-account' : 'mdi-company'"
          ></v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ localValue.name }}</v-list-item-title>
          <v-list-item-subtitle
            >{{ __('Documento fiscal') }}:
            {{ localValue.fiscal_number }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ __('Email') }}:
            {{ localValue.email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ __('Telefone principal') }}:
            {{ localValue.contact1 }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ __('Telefone secundário') }}:
            {{ localValue.contact2 }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ __('IBAN') }}:
            {{ localValue.iban }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ __('Gênero') }}:
            {{ localValue.gender }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ __('Informação adicional') }}:
            {{ localValue.aditional_info }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn @click="openContactDialog(localValue.id)" icon>
            <v-icon size="21">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <v-dialog v-model="contact_dialog" max-width="600px">
      <contact-form
        v-if="contact_dialog"
        @cancel="handleCloseDialog"
        @submit="handleSubmitDialog"
        :id="edit_contact_id"
      ></contact-form>
    </v-dialog>
  </div>
</template>

<script>
import ContactForm from "@/components/forms/ContactForm";
import { listContactsWithAddresses } from "@/services/contact";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  name: "select-contact",
  props: {
    value: {
      type: Object
    },
    hideDetailContact: {
      type: Boolean,
      default: false
    }
  },
  components: { ContactForm },
  data: () => ({
    i18n_prefix: 'select_contact',
    search: "",
    contacts: [],
    fetching_contacts: false,
    contact: null,

    // Contact Dialog
    contact_dialog: false,
    edit_contact_id: null
  }),
  methods: {
    openContactDialog(edit_contact_id = null) {
      this.contact_dialog = true;
      this.edit_contact_id = edit_contact_id;
    },
    handleCloseDialog() {
      this.contact_dialog = false;
      this.edit_contact_id = null;
    },
    handleSubmitDialog(payload) {
      const found_contact = this.contacts.find(
        contact => contact.id == payload.id
      );

      if (found_contact) {
        this.contacts = this.contacts.map(contact => {
          if (contact.id == found_contact.id) {
            return payload;
          }

          return contact;
        });
      } else {
        this.contacts.push(payload);
      }

      this.localValue = payload;
      this.handleCloseDialog();
    },
    async fetchContacts(filter) {
      if (this.fetching_contacts) return;

      const already_has_contact = this.contacts.some(contact => contact.name.includes(filter));
      if (!already_has_contact) {
        this.fetching_contacts = true;
        const response = await listContactsWithAddresses(filter);
        
        this.contacts =  response.data;
        this.fetching_contacts = false;
      }
    },
    setInitialValue() {
      if (this.value) {
        this.localValue = this.value;
        this.contacts.push(this.value);
      }
    }
  },
  computed: {
    localValue: {
      get: function() {
        return this.contact ? this.contact : {};
      },
      set: function(newValue) {
        this.contact = newValue;

        if (newValue == null) {
          this.$emit("input", null);
          return;
        }
        this.$emit("input", newValue);
      }
    },
  },
  watch: {
    value(value) {
      if (!value) this.contact = null;
      this.setInitialValue();
    },
    search: _.debounce(
      function(value) {
        this.fetchContacts(value);
      }, 
      500, 
      { 
        leading: true, // Sempre executa no primeiro carregamento
        trailing: true 
      }
    )
  },
  created() {
    this.setInitialValue();
  }
};
</script>
