import Vue from "vue";


export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/unities", {
    params
  });

  return response.data;
}

export async function show(params = {}, id) {
  const response = await Vue.prototype.$http.get("/unities/" + id, {
    params
  });

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/unities", payload);

  return response.data;
}

export async function update(payload, id) {
  const response = await Vue.prototype.$http.put("/unities/" + id, payload);

  return response.data;
}

export async function destroy(id) {
  await Vue.prototype.$http.delete("/unities/" + id);

  return true;
}

export async function getSugestionForLead(lead_number) {
  const response = await Vue.prototype.$http.get(`/unities/sugestion/${lead_number}`);

  return response.data;
}


export async function getSugestion(city_id, team_id) {
  const response = await Vue.prototype.$http.get("/unities/sugestion", {
    params: {
      city_id,
      team_id
    }
  });

  return response.data;
}
