<template>
  <v-select
    :label="label"
    :value="value"
    @input="handleInput"
    :items="subtypes"
    item-value="id"
    item-text="description"
    :error-messages="errorMessages"
    :loading="loading"
    :multiple="multiple"
    :return-object="returnObject"
    v-bind="$attrs"
  >
    <template v-slot:selection="{ index }" v-if="counterMode">
      <span v-if="index === 0" class="grey--text mr-2">
        {{ label }}
      </span>
    </template>
    <template v-slot:append v-if="counterMode">
      <v-avatar size="20" color="orange darken-3" v-if="multiple && value.length > 0">
        <span class="white--text text-caption">{{ value.length }}</span>
      </v-avatar>
      <v-icon right>mdi-menu-down</v-icon>
    </template>
  </v-select>
</template>

<script>
import { indexByLeadType, indexAll } from "@/services/subtypes/subtypes";

export default {
  props: {
    leadTypeId: Number,
    value: [String, Array, Object],
    label: {
      type: String,
      default: "Subtipo",
    },
    errorMessages: {
      type: Array,
      default: null,
    },
    reset: {
      type: Function,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allLeadTypes: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    // Counter mode implementado para os filtros na tela de leads
    counterMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      subtypes: [],
      loading: false,
      currentLeadType: null,
    };
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
    async handleGetSubtypesByLeadType(leadTypeId) {
      this.loading = true;
      this.subtypes = [];

      try {
        const response = await indexByLeadType(leadTypeId);
        this.subtypes = response.data;
        this.currentLeadType = leadTypeId;
      } finally {
        this.loading = false;
      }
    },
    async handleGetAllSubtypes() {
      this.loading = true;
      this.subtypes = [];

      try {
        const response = await indexAll();
        this.subtypes = response.data;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    async leadTypeId(value, oldValue) {
      if (!value) {
        return;
      }

      if (value !== oldValue && oldValue !== null) {
        this.handleInput(null);
        if (this.reset) {
          this.reset();
        }
      }

      this.handleGetSubtypesByLeadType(value);
    },
  },
  created() {
    if (this.allLeadTypes) {
      this.handleGetAllSubtypes();
    }
  },
};
</script>
