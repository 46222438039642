<template>
  <v-dialog :value="value" @change="handleChange" persistent max-width="800px">
    <v-card>
      <v-app-bar flat>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form
        @submit.prevent="submit"
        class="pa-2"
      >
        <validation-observer ref="form">
          <v-card-text>
            <validation-provider
              name="type"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                :label="$capitalize($tc('model.form_lead_type_money_safe'))"
                v-model="form.type"
                :error-messages="errors"
                :items="[
                  $capitalize($tc('model.form_lead_contents_type_money_safe')),
                  $capitalize($tc('model.form_lead_fraction_type_money_safe'))
                ]"
              />
            </validation-provider>
            <validation-provider
              name="value"
              rules="required"
              v-slot="{ errors }"
            >
              <money-input
                v-model="form.value"
                type="text"
                :label="$capitalize($tc('model.form_lead_value_money_safe'))"
                :options="money"
                :error-messages="errors"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" outlined color="primary">{{$capitalize($tc('model.form_lead_save_button'))}}</v-btn>
          </v-card-actions>
        </validation-observer>
      </v-form>
    </v-card>
  </v-dialog>  
</template>

<script>
import MoneyInput from '@/components/MoneyInput';

export default {
  name: "insurance-object-form-dialog",
  components: { MoneyInput },
  props: {
    value: Boolean,
    object: Object,
    errors: Array
  },
  data: function() {
    return {
      money: {
        prefix: "€ ",
        suffix: "",
        precision: 2,
        length: 11,
        locale: "pt-PT"
      },
      form: {
        type: null,
        value: 0
      }
    };
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
    },
    resetForm() {
      this.$refs.form.reset();
      this.form = {
        capital: 0,
        franchise_value: 0,
        franchise: 0,
        franchise_min: 0,
        franchise_max: 0
      };
    },
    getInsuranceObject() {
      this.form = {
        ...this.object
      };
    },
    async submit() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      this.$listeners.submit(this.form);
      this.handleClose();
    },
  },
  computed: {
    editMode() {
      return this.object != null;
    },
    dialogTitle() {
      if (this.editMode)
        return this.object?.type;

      return this.$capitalize(this.$tc("model.form_lead_new_money_safe"));
    }
  },
  watch: {
    value: function(value) {
      if (value && this.object) this.getInsuranceObject();
      if (!value) this.resetForm();
    },
    errors: function(value) {
      if (value) {
        this.$refs.form.setErrors(value);
      }
    }
  }
}
</script>

<style>

</style>