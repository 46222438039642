<template>
  <v-autocomplete
    :items="types_expertise"
    item-text="description"
    item-value="is_traditional_appraisal"
    @change="handleSelect"
    :loading="loading"
    :value="appraisal"
    v-bind="$props"
    return-object
    :disabled="loading"
  >
  </v-autocomplete>
</template>

<script>
import i18n from '@/mixins/i18n';
import { getAppraisals } from "@/services/appraisals";

export default {
  name: "select-appraisal",
  mixins: [i18n],
  props: {
    appraisal: Boolean,
    label: String,
    clearable: Boolean,
  },
  model: {
    prop: "appraisal",
    event: "change"
  },
  data: () => ({
    types_expertise: [],
    loading: false,
  }),
  methods: {
    async fetchAppraisals() {
      this.loading = true;
      this.types_expertise = await getAppraisals();
      this.loading = false;
    },

    handleSelect(value) {
      this.$emit("change", value);
    }
  },
  created() {
    this.fetchAppraisals();
  },
};
</script>