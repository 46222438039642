import Vue from "vue";


export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/teams", {
    params
  });

  return response.data;
}

export async function show(id) {
  const response = await Vue.prototype.$http.get("/teams/" + id);

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/teams", payload);

  return response.data;
}

export async function update(payload, id) {
  const response = await Vue.prototype.$http.put("/teams/" + id, payload);

  return response.data;
}

export async function destroy(id) {
  await Vue.prototype.$http.delete("/teams/" + id);

  return true;
}
