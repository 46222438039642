<template>
  <v-card outlined>
    <v-card-title> {{ __('Seguradora', 'new_lead') }} </v-card-title>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-shield-account-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="insurer">
            {{ insurer.name }}
          </v-list-item-title>
          <v-skeleton-loader v-else type="text" />
          <v-list-item-subtitle v-if="insurer">
            {{ __('Contatos', 'leads') }}: {{ insurer.contact1 }}
            {{ insurer.contact2 }}
          </v-list-item-subtitle>
          <v-skeleton-loader v-else type="text" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import i18n from '@/mixins/i18n'; 

export default {
  mixins: [i18n],
  props: {
    insurer: Object
  },
  data() {
    return {
    };
  },
  methods: {
  }
}
</script>

<style>

</style>