<template>
  <v-text-field
    v-bind="$attrs"
    name="postal_code"
    type="text"
    persistent-hint
    :value="value"
    @input="handleInput"
    :counter="15"
    :disabled="postal_code_loading"
    autofocus
    autocomplete="disabled"
  >
    <template v-slot:append-outer>
      <v-menu left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-on="on" 
            v-bind="attrs"
            @click="getPostalAddresses"
            :disabled="postal_code_loading || value == null"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-card max-width="450px">
          <empty-state
            :when="found_postal_addresses.length == 0"
            message="Não encontrou nehum endereço para o código postal informado"
          >
            <v-card-text>
              <v-skeleton-loader
                v-if="postal_code_loading"
                type="list-item,list-item"
              ></v-skeleton-loader>
              <v-list v-else>
                <v-list-item v-for="(address, index) in found_postal_addresses" :key="'address-' + index">
                  <v-list-item-avatar size="30" color="grey lighten-2">
                    <v-icon small>mdi-map-marker</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ address.street }}
                      {{ address.number ? ', ' + address.number : '' }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Distrito:
                      {{ address.country_area.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Concelho:
                      {{ address.city.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Freguesia:
                      {{ address.city_area ? address.city_area.name : 'N/A' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon color="primary" @click="apply(address)">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </empty-state>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import { autocompleteZipCode } from "@/services/locals";

import EmptyState from '@/components/EmptyState';
import i18n from '@/mixins/i18n';

export default {
  name: 'postal-code-address-input',
  mixins: [i18n],
  components: {
    EmptyState,
  },
  props: {
    value: {
      type: String
    },
  },
  data() {
    return {
      postal_code_loading: false,
      found_postal_addresses: [],
    }
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
    apply(address) {
      this.$emit("apply", address);
    },
    async getPostalAddresses() {
      if (!this.value) return;

      this.postal_code_loading = true;
      try {
        const address = await autocompleteZipCode("pt", this.value);

        this.found_postal_addresses = address.streets.map(street => ({
          street,
          country_area: address.country_area ? {
            id: address.country_area.country_area_id,
            name: address.country_area.country_area,
          } : {},
          city: address.city,
          city_area: address.city_area ? {
            id: address.city_area.city_area_id,
            name: address.city_area.city_area,
          } : {}
        }));
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.__('Falha ao consultar', 'address'),
          color: "red",
        });
      } finally {
        this.postal_code_loading = false;
      }
    },
  }
}
</script>

<style>

</style>