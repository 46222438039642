<script>
import { update, store } from "@/services/lead";
import { storeLead } from "@/services/lusitania/robots/jobs";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  data() {
    return {
      i18n_prefix: "managers",
      loading: false,
      loadingProgressionValue: 0,
      loadingProgressionText: this.__("A carregar"),
    };
  },

  methods: {
    async updateLead(event) {
      const leadNumber = this.$route.params.lead_number;

      // Loading
      const totalSteps = 1;
      const stepValue = 100 / totalSteps;

      this.loading = true;
      try {
        this.setProgressionValue(50);
        this.loadingProgressionText = this.__(
          "Salvando alterações da lead",
          "new_lead"
        );

        await update(leadNumber, event);
        this.addProgressionValue(stepValue);
        this.setProgressionValue(100);
        this.loadingProgressionText = this.__("Lead salva!", "new_lead");
        this.$router.push({
          name: "lead_detail_data",
          params: {
            lead_number: leadNumber,
          },
        });
      } catch (error) {
        if (error.response.status == 422) {
          this.$refs.leadForm.setErrors(error?.response?.data?.errors || []);
        } else if (error.response.status == 400) {
          this.$store.commit("sendMessage", {
            color: "red",
            text: error.response.data.message,
          });
        } else {
          this.$store.commit("sendMessage", {
            color: "red",
            text: this.__(
              "Não foi possível atualizar a lead, informe o suporte",
              "new_lead"
            ),
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async createLead(event) {
      const insurer_id = this.$route.params.insurer_id;

      // Loading
      const totalSteps = 1;
      const stepValue = 100 / totalSteps;

      this.loading = true;
      try {
        this.setProgressionValue(50);
        this.loadingProgressionText = this.__(
          "Salvando dados da lead",
          "new_lead"
        );
        // Lead Data
        const data = await store(insurer_id, event);
        this.addProgressionValue(stepValue);
        this.setProgressionValue(100);
        this.loadingProgressionText = this.__("Lead salva!", "new_lead");
        this.$router.push({
          name: "lead_detail_data",
          params: {
            lead_number: data.identifier_code,
          },
        });
      } catch (error) {
        if (error.response.status == 422) {
          this.$refs.leadForm.setErrors(error?.response?.data?.errors || []);
        } else if (error.response.status == 400) {
          this.$store.commit("sendMessage", {
            color: "red",
            text: error.response.data.message,
          });
        } else {
          this.$store.commit("sendMessage", {
            color: "red",
            text: this.__(
              "Não foi possível atualizar a lead, informe o suporte",
              "new_lead"
            ),
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async createLeadFromJob(event) {
      const job_id = this.$route.params.job_id;

      // Loading
      const totalSteps = 1;
      const stepValue = 100 / totalSteps;

      this.loading = true;
      try {
        this.setProgressionValue(50);
        this.loadingProgressionText = "Salvando dados da lead";
        // Lead Data
        const data = await storeLead(job_id, event);
        this.addProgressionValue(stepValue);
        this.setProgressionValue(100);
        this.loadingProgressionText = "Lead salva!";
        this.$router.push({
          name: "lead_detail_data",
          params: {
            lead_number: data.identifier_code,
          },
        });
      } catch (error) {
        if (error.response.status == 422) {
          this.$refs.leadForm.setErrors(error?.response?.data?.errors || []);
        } else if (error.response.status == 400) {
          this.$store.commit("sendMessage", {
            color: "red",
            text: error.response.data.message,
          });
        } else {
          this.$store.commit("sendMessage", {
            color: "red",
            text: "Não foi possível cadastrar a lead, informe o suporte",
          });
        }
      } finally {
        this.loading = false;
      }
    },
    addProgressionValue(value) {
      this.setProgressionValue(this.loadingProgressionValue + value);
    },
    setProgressionValue(value) {
      this.loadingProgressionValue = value;
    },
    delay(delay) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    },
  },
};
</script>
