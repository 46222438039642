import Vue from "vue";

export async function indexByLeadType(leadTypeId) {
  const response = await Vue.prototype.$http.get(
    `lead_types/${leadTypeId}/subtypes`
  );

  return response.data;
}

export async function indexAll() {
  const response = await Vue.prototype.$http.get(`lead_subtypes`);

  return response.data;
}
