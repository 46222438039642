<template>
  <div>
    <v-row dense>
      <v-col>
        <ShowInsurer :insurer="insurer" />
      </v-col>
    </v-row>
    <ValidationObserver ref="leadForm">
      <v-form @submit.prevent="handleSubmit">
        <v-row dense>
          <v-col>
            <v-card outlined>
              <v-card-title>{{
                __("Segurado e lesado", "new_lead")
              }}</v-card-title>
              <v-card-text>
                <ValidationProvider
                  name="form_lead.insured_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select-contact
                    v-model="form.insured"
                    item-text="name"
                    :label="__('Segurado', 'form_lead')"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <v-switch
                  v-model="is_insured_participant"
                  :label="__('Segurado é lesado?', 'new_lead')"
                />
                <ValidationProvider
                  name="form_lead.injured_id"
                  :rules="form.is_insured_participant ? '' : 'required'"
                  v-slot="{ errors }"
                >
                  <select-contact
                    v-model="form.injured"
                    item-text="name"
                    :label="__('Lesado', 'new_lead')"
                    :error-messages="errors"
                    v-if="!form.is_insured_participant"
                  />
                </ValidationProvider>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-card outlined class="mb-4">
              <v-card-title>{{
                __("Tipo de peritagem", "new_lead")
              }}</v-card-title>
              <v-card-text>
                <ValidationProvider
                  name="form_lead.is_traditional_appraisal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select-appraisal
                    v-model="form.is_traditional_appraisal"
                    :label="__('Peritagem', 'appraisals')"
                    :error-messages="errors"
                    clearable
                    autocomplete="disabled"
                  />
                </ValidationProvider>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-card outlined class="mb-4">
              <v-card-title>{{
                __("Local de risco do lesado", "new_lead")
              }}</v-card-title>
              <v-card-text>
                <ValidationProvider
                  name="form_lead.postal_code"
                  rules="max:15"
                  v-slot="{ errors }"
                >
                  <postal-code-address-input
                    v-model="form.postal_code"
                    :error-messages="errors"
                    :label="__('CEP', 'address')"
                    :hint="
                      $capitalize(
                        $tc('model.interface_not_necessary_field_hint', 1)
                      )
                    "
                    @apply="applyFoundAddress"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.country_area_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select-country-area
                    v-model="form.country_area"
                    :label="__('Estado', 'address')"
                    :error-messages="errors"
                    clearable
                    autocomplete="disabled"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.city_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select-city
                    v-model="city"
                    :country_area="form.country_area"
                    :label="__('Cidade', 'address')"
                    :error-messages="errors"
                    name="354643563456"
                    clearable
                    autocomplete="disabled"
                  ></select-city>
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.city_area_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select-city-area
                    v-model="form.city_area"
                    :city="city"
                    :label="__('Bairro', 'address')"
                    :error-messages="errors"
                    clearable
                    return-object
                    autocomplete="disabled"
                  ></select-city-area>
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.street"
                  rules="required|max:100"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="form.street"
                    :label="__('Rua', 'address')"
                    :error-messages="errors"
                    autocomplete="disabled"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.address_number"
                  rules="max:45"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="form.number"
                    :label="__('Número', 'address')"
                    :error-messages="errors"
                    autocomplete="disabled"
                  />
                </ValidationProvider>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-card outlined>
              <v-card-title>{{ __("Sinistro", "form_lead") }}</v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      name="form_lead.lead_type"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        v-model="lead_type"
                        :items="lead_types"
                        :label="__('Tipo', 'form_lead')"
                        item-text="description"
                        item-value="id"
                        :error-messages="errors"
                        :return-object="false"
                        :readonly="editMode"
                        :disabled="editMode"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      name="form_lead.subtype_id"
                      rules="required"
                      v-slot="{ errors, reset }"
                    >
                      <LeadSubtypeSelect
                        v-model="form.subtype_id"
                        :leadTypeId="lead_type"
                        :error-messages="errors"
                        :reset="reset"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <ValidationProvider
                  name="form_lead.claim_date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="date"
                    :label="__('Ocorreu em', 'form_lead')"
                    v-model="form.claim_date"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.claim_involvement_date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="date"
                    :label="
                      $capitalize(
                        $tc('model.form_lead_date_participation_sinister')
                      )
                    "
                    v-model="form.claim_involvement_date"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.claim_description"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    :label="__('Reclamação do Lesado', 'new_lead')"
                    v-model="form.claim_description"
                    rows="2"
                    :error-messages="errors"
                    filled
                  />
                </ValidationProvider>

                <v-card-title class="pl-0">
                  {{ __("Informações da seguradora", "new_lead") }}
                </v-card-title>
                <ValidationProvider
                  name="form_lead.external_reference"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :label="
                      $capitalize(
                        $tc('model.form_lead_external_reference_company')
                      )
                    "
                    v-model="form.external_reference"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.manager"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :label="$capitalize($tc('model.form_lead_manager_company'))"
                    v-model="form.manager"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.creation_date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="date"
                    :label="
                      $capitalize(
                        $tc('model.form_lead_started_process_company')
                      )
                    "
                    v-model="form.creation_date"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Policy -->
        <v-row dense>
          <v-col>
            <v-card outlined>
              <v-card-title>{{
                $capitalize($tc("model.form_lead_policy_title"))
              }}</v-card-title>
              <v-card-text>
                <ValidationProvider
                  name="form_lead_policy.number"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :label="$capitalize($tc('model.form_lead_number_policy'))"
                    v-model="form.policy.number"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead_policy.begin_date"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="date"
                    :label="
                      $capitalize($tc('model.form_lead_initial_date_policy'))
                    "
                    v-model="form.policy.begin_date"
                    :error_messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead_policy.end_date"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="date"
                    :label="
                      $capitalize($tc('model.form_lead_final_date_policy'))
                    "
                    v-model="form.policy.end_date"
                    :error_messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-card-text>
              <v-card-title class="pb-2">
                {{ $capitalize($tc("model.form_lead_money_safe_title")) }}
                <v-btn icon @click="createInsuranceObject"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-card-title>
              <div
                class="v-messages theme--light error--text ml-4 errors-insurance-object"
                role="alert"
                v-show="insurance_object_errors.length > 0"
              >
                <div class="v-messages__wrapper">
                  <div
                    class="v-messages__message pt-2"
                    v-for="(error, index) in insurance_object_errors"
                    :key="'insurance-object-error' + index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <v-card-text>
                <v-data-table
                  :headers="headers_insurance_objects"
                  :items="enumerated_insurance_objects"
                  fixed-header
                  max-height="500"
                  item-key="id"
                  dense
                  hide-default-footer
                >
                  <template v-slot:item.value="{ item }">{{
                    formatMoney(item.value)
                  }}</template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn icon @click="editInsuranceObject(item)"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn icon @click="removeInsuranceObject(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-title class="pb-2">
                {{ $capitalize($tc("model.form_lead_coverings_title")) }}
                <v-btn icon @click="createCoverage"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-card-title>
              <div
                class="v-messages theme--light error--text ml-4 errors-coverage"
                role="alert"
                v-show="coverage_errors.length > 0"
              >
                <div class="v-messages__wrapper">
                  <div
                    class="v-messages__message pt-2"
                    v-for="(error, index) in coverage_errors"
                    :key="'coverage-error' + index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <v-card-text>
                <v-data-table
                  :headers="headers_coverages"
                  :items="enumerated_coverages"
                  fixed-header
                  max-height="500"
                  show-select
                  single-select
                  item-key="id"
                  dense
                >
                  <template v-slot:header.data-table-select>
                    <v-icon>mdi-check</v-icon>
                  </template>
                  <template v-slot:item.data-table-select="{ item }">
                    <v-checkbox
                      :input-value="item.selected"
                      @change="selectCoverage(item, $event)"
                    />
                  </template>

                  <template v-slot:item.main_coverage="{ item }">
                    <v-icon
                      color="primary"
                      @click="selectMainCoverage(item, !item.main)"
                      :disabled="!item.selected"
                    >
                      {{
                        !item.main
                          ? "mdi-radiobox-blank"
                          : "mdi-radiobox-marked"
                      }}
                    </v-icon>
                  </template>

                  <template v-slot:item.capital="{ item }">{{
                    formatMoney(item.capital)
                  }}</template>

                  <template v-slot:item.franchise_value="{ item }">{{
                    formatMoney(item.franchise_value)
                  }}</template>

                  <template v-slot:item.franchise="{ item }">
                    {{ item.franchise }}%
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn icon @click="editCoverage(item)"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn icon @click="removeCoverage(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-card outlined>
              <v-card-title>{{
                $capitalize($tc("model.form_lead_management_title"))
              }}</v-card-title>
              <v-card-text>
                <ValidationProvider
                  name="form_lead.team_id"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="teams"
                    item-text="name"
                    v-model="team"
                    return-object
                    :label="$capitalize($tc('model.teams_team_label'))"
                    :loading="fetching_teams"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.service_manager_id"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="managers"
                    item-text="user_name"
                    v-model="service_manager"
                    :label="$capitalize($tc('model.teams_manager_label'))"
                    :loading="fetching_managers"
                    :error-messages="errors"
                    :disabled="!enableManager"
                    :hint="
                      enableManager
                        ? null
                        : $capitalize(
                            $tc('model.form_lead_first_local_and_lead_warning')
                          )
                    "
                    persistent-hint
                    return-object
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="form_lead.unity_id"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="unities"
                    item-text="user.name"
                    v-model="unity"
                    return-object
                    :label="$capitalize($tc('model.teams_unity_label'))"
                    :loading="fetching_managers"
                    :error-messages="errors"
                    :disabled="!enableUnity"
                    :hint="
                      enableUnity
                        ? null
                        : $capitalize(
                            $tc('model.form_lead_first_local_and_lead_warning')
                          )
                    "
                    persistent-hint
                  />
                </ValidationProvider>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <slot :form="form"></slot>

        <!-- Action Bar -->
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title> </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="handleCancel()" class="mr-2" elevation="0">
                  {{ __("Cancelar", "leads") }}
                </v-btn>
                <v-btn type="submit" color="primary" :disabled="!enabledSave">{{
                  $capitalize($tc("model.form_lead_save_button"))
                }}</v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>

    <insurance-object-form-dialog
      v-model="insurance_object_dialog"
      :object="opened_insurance_object"
      @submit="submitInsuranceObject"
    />

    <coverage-form-dialog
      v-model="coverage_dialog"
      :coverage="opened_coverage"
      @submit="submitCoverage"
    />
  </div>
</template>

<script>
import _ from "lodash";
import Money from "@/tools/money";

import { index as indexTeams } from "@/services/teams";
import { index as indexManagers } from "@/services/service_managers";
import { getSugestion } from "@/services/unities";

import ShowInsurer from "@/components/Lead/Form/ShowInsurer";
import SelectContact from "@/components/SelectContact";
import SelectCountryArea from "@/components/locals/SelectCountryArea";
import SelectAppraisal from "@/components/LeadAppraisals/SelectAppraisal";
import SelectCity from "@/components/locals/SelectCity";
import SelectCityArea from "@/components/locals/SelectCityArea";
import PostalCodeAddressInput from "@/components/locals/PostalCodeAddressInput";

import InsuranceObjectFormDialog from "@/components/Lead/Form/InsuranceObjectFormDialog";
import CoverageFormDialog from "@/components/Lead/Form/CoverageFormDialog";

import LeadSubtypeSelect from "../../LeadSubtypes/LeadSubtypeSelect.vue";

import i18n from "@/mixins/i18n";

export default {
  components: {
    ShowInsurer,
    SelectContact,
    SelectCountryArea,
    SelectCity,
    SelectCityArea,
    PostalCodeAddressInput,
    InsuranceObjectFormDialog,
    CoverageFormDialog,
    LeadSubtypeSelect,
    SelectAppraisal,
  },
  mixins: [i18n],
  data() {
    return {
      form: {
        insured: null,
        injured: null,
        postal_code: null,
        country_area: null,
        is_traditional_appraisal: null,
        city: null,
        city_area: null,
        street: null,
        number: null,
        lead_type: null,
        subtype_id: null,
        claim_date: null,
        claim_involvement_date: null,
        claim_description: null,
        external_reference: null,
        manager: null,
        creation_date: null,
        team: null,
        unity: null,
        service_manager: null,
        is_insured_participant: true,
        policy: {
          number: null,
          begin_date: null,
          end_date: null,
          insurance_objects: [],
          coverages: [],
          limits: [],
          franchise: [],
        },
        additional_information: [],
      },

      // Watched Properties
      is_insured_participant: true,
      team: null,
      service_manager: null,
      unity: null,
      city: null,
      lead_type: null,

      // Address Autocomplete
      streets: [],
      searching_postal_code: false,

      //Management
      fetching_teams: false,
      teams: [],

      fetching_managers: false,
      managers: [],

      fetching_unities: false,
      unities: [],

      // Insurance Objects
      headers_insurance_objects: [
        {
          text: this.__("Descrição", "policy"),
          value: "type",
        },
        {
          text: this.__("Valor", "policy"),
          value: "value",
        },
        {
          text: "",
          value: "actions",
          width: 120,
        },
      ],
      insurance_object_dialog: false,
      opened_insurance_object: null,

      // Policy Coverages
      headers_coverages: [
        {
          text: "Principal",
          value: "main_coverage",
          width: 100,
        },
        {
          text: this.__("Produtos", "policy", 1),
          value: "product_description",
        },
        {
          text: this.__("Descrição", "policy", 1),
          value: "type_description",
        },
        {
          text: this.__("Capital", "policy", 1),
          value: "capital",
          align: "right",
        },
        {
          text: this.__("Valor", "policy", 1),
          value: "franchise_value",
          align: "right",
        },
        {
          text: this.__("Franquia", "policy", 1),
          value: "franchise",
          align: "center",
        },
        {
          text: "",
          value: "actions",
          width: 120,
        },
      ],
      coverage_dialog: false,
      opened_coverage: null,
      insurance_object_errors: [],
      coverage_errors: [],
    };
  },
  computed: {
    editMode() {
      return this.$route.params["lead_number"] != null;
    },

    enabledSave() {
      if (this.editMode) {
        return this.$store.getters["auth/getScope"]("leads.edit");
      }

      return this.$store.getters["auth/getScope"]("leads.add");
    },

    insurer() {
      return this.$store.getters["lead_form/getInsurer"];
    },
    lead_types() {
      return this.$store.getters["lead_form/getLeadTypes"];
    },

    enableUnity() {
      return this.team?.id && this.city?.id && this.lead_type;
    },
    enableManager() {
      return this.team?.id && this.lead_type;
    },
    enumerated_insurance_objects() {
      return this.form.policy.insurance_objects.map((item, index) => ({
        ...item,
        index,
      }));
    },
    enumerated_coverages() {
      return this.form.policy.coverages.map((item, index) => ({
        ...item,
        index,
        selected: item.selected ? true : false,
        main: item.main ? true : false,
      }));
    },
  },
  watch: {
    is_insured_participant(value) {
      this.form.is_insured_participant = value;
      this.form.injured = null;
    },
    team: function (value) {
      this.form.team = value;
      this.fetchManagers(value?.id);

      if (this.team?.id && this.lead_type && this.city?.id) this.fetchUnities();
    },
    city: function (value) {
      this.form.city = value;
      if (this.team?.id && this.lead_type && this.city?.id) this.fetchUnities();
    },
    lead_type: function (value) {
      this.form.lead_type = value;
      if (this.team?.id && this.lead_type && this.city?.id) this.fetchUnities();
    },

    service_manager(value, old_value) {
      if (old_value === null && value?.id && this.managers.length == 0) {
        this.managers = [value];
      }
    },
    unity(value, old_value) {
      if (old_value === null && value?.id && this.unities.length == 0) {
        this.unities = [value];
      }
    },
  },
  methods: {
    applyFoundAddress(address) {
      this.form.country_area = address.country_area;
      this.city = address.city;
      this.form.city_area = address.city_area;
      this.form.street = address.street;
    },

    scrollToError() {
      this.$nextTick(() => {
        const el_form_error = this.$el.querySelector(
          ".v-messages.error--text:first-of-type"
        );

        if (el_form_error) {
          this.$vuetify.goTo(el_form_error);
          return;
        } else {
          let el_array_error = this.$el.querySelector(
            ".errors-insurance-object"
          );
          el_array_error = el_array_error
            ? el_array_error
            : this.$el.querySelector(".errors-coverage");

          if (el_array_error) {
            this.$vuetify.goTo(el_array_error);
            return;
          }
        }
      });
    },

    async validateForm() {
      let validation = true;
      validation = await this.$refs.leadForm.validate();
      this.insurance_object_errors = [];
      this.coverage_errors = [];

      if (this.form.policy.insurance_objects.length == 0) {
        this.insurance_object_errors.push(
          this.__(
            "É obrigatório registar ao menos uma verba segura.",
            "new_lead"
          )
        );
        validation = false;
      }

      if (this.form.policy.coverages.length == 0) {
        this.coverage_errors.push(
          this.__("É obrigatório registar ao menos uma cobertura.", "new_lead")
        );
        validation = false;
      }

      return validation;
    },

    setInsuranceObjectsErrors(errors) {
      const unique_errors = [];
      for (var key in errors) {
        if (key.startsWith("form_lead_policy.insurance_objects")) {
          unique_errors.push(...errors[key]);
        }
      }
      this.insurance_object_errors = _.uniq(unique_errors);
    },

    setCoveragesErrors(errors) {
      const unique_errors = [];
      for (var key in errors) {
        if (key.startsWith("form_lead_policy.coverages")) {
          unique_errors.push(...errors[key]);
        }
      }

      this.coverage_errors = _.uniq(unique_errors);
    },

    async handleSubmit() {
      const validation = await this.validateForm();

      if (!validation) {
        this.scrollToError();
        return;
      }

      this.$emit("submit", {
        form_lead: {
          ...this.form,
          city_id: this.form.city?.id,
          city_area_id: this.form.city_area?.id,
          country_area_id: this.form.country_area?.id,
          insurer_id: this.form.insurer?.id,
          insured_id: this.form.insured?.id,
          injured_id: this.form.injured?.id,
          team_id: this.form.team?.id,
          service_manager_id: this.service_manager?.id,
          unity_id: this.unity?.id,
          is_insured_participant: this.is_insured_participant,
          is_traditional_appraisal:
            this.form.is_traditional_appraisal?.is_traditional_appraisal,
        },
        form_lead_policy: {
          ...this.form.policy,
          insurance_objects: this.enumerated_insurance_objects,
          coverages: this.enumerated_coverages,
        },
      });
    },

    fill(form) {
      this.form = {
        ...this.form,
        ...form,
        policy: {
          ...this.form.policy,
          ...form.policy,
        },
        subtype_id: form.subtype_id,
      };
      this.is_insured_participant = this.form.is_insured_participant;
      this.team = this.form.team;
      this.service_manager = this.form.service_manager;
      this.unity = this.form.unity;
      this.city = this.form.city;
      this.lead_type = this.form.lead_type;
      this.is_traditional_appraisal = this.form.is_traditional_appraisal;
      this.$nextTick(() => {
        this.$refs.leadForm.reset();
      });
    },

    handleCancel() {
      this.$emit("cancel");
    },
    setErrors(errors) {
      this.$refs.leadForm.setErrors(errors);
      this.setInsuranceObjectsErrors(errors);
      this.setCoveragesErrors(errors);
      this.scrollToError();
    },

    formatMoney(value) {
      return Money.euro(value ? value : 0);
    },

    // Insurance Objects
    createInsuranceObject() {
      this.insurance_object_dialog = true;
      this.opened_insurance_object = null;
    },
    editInsuranceObject(insurance_object) {
      this.insurance_object_dialog = true;
      this.opened_insurance_object = insurance_object;
    },
    removeInsuranceObject(insurance_object) {
      this.form.policy.insurance_objects.splice(insurance_object.index, 1);
    },
    submitInsuranceObject(insurance_object) {
      if (this.opened_insurance_object == null) {
        this.form.policy.insurance_objects.push(insurance_object);
      } else {
        this.form.policy.insurance_objects =
          this.form.policy.insurance_objects.map((rec, index) => {
            if (this.opened_insurance_object?.index === index)
              return insurance_object;

            return rec;
          });
      }
    },

    // Policy Coverages
    selectCoverage(item, selected) {
      this.form.policy.coverages = this.form.policy.coverages.map(
        (rec, index) => {
          if (item?.index === index)
            return {
              ...rec,
              selected: selected,
              main: selected ? rec.main : false,
            };

          return rec;
        }
      );
    },
    selectMainCoverage(item, main) {
      this.form.policy.coverages = this.form.policy.coverages.map(
        (rec, index) => {
          if (item?.index === index)
            return {
              ...rec,
              main: main,
            };

          if (main) {
            return {
              ...rec,
              main: false,
            };
          }

          return rec;
        }
      );
    },
    createCoverage() {
      this.coverage_dialog = true;
      this.opened_coverage = null;
    },
    editCoverage(coverage) {
      this.coverage_dialog = true;
      this.opened_coverage = coverage;
    },
    removeCoverage(coverage) {
      this.form.policy.coverages.splice(coverage.index, 1);
    },
    submitCoverage(coverage) {
      if (this.opened_coverage == null) {
        this.form.policy.coverages.push(coverage);
      } else {
        this.form.policy.coverages = this.form.policy.coverages.map(
          (rec, index) => {
            if (this.opened_coverage?.index === index) return coverage;

            return rec;
          }
        );
      }

      this.opened_coverage = null;
    },

    //Management
    async fetchTeams() {
      this.fetching_teams = true;
      try {
        const response = await indexTeams({ list: true });
        this.teams = response;
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.fetching_teams = false;
      }
    },
    async fetchManagers(team_id) {
      this.fetching_managers = true;
      try {
        const response = await indexManagers({
          list: true,
          team_id,
        });
        this.managers = response;
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.fetching_managers = false;
      }
    },
    async fetchUnities() {
      this.fetching_unities = true;
      try {
        const response = await getSugestion(this.city?.id, this.team?.id);
        this.unities = response;
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.fetching_unities = false;
      }
    },
  },
  created() {
    this.fetchTeams();
  },
};
</script>

<style></style>
