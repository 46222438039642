<template>
  <v-dialog :value="value" @change="handleChange" persistent max-width="800px">
    <v-card>
      <v-app-bar flat>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form @submit.prevent="submit" class="pa-2">
        <validation-observer ref="form">
          <v-card-text>
            <validation-provider
              name="product_description"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="$capitalize($tc('model.form_lead_product_coverings'))"
                v-model="form.product_description"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              name="type_description"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="$capitalize($tc('model.form_lead_covering_form'))"
                v-model="form.type_description"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              name="capital"
              rules="required"
              v-slot="{ errors }"
            >
              <money-input
                v-model="form.capital"
                type="text"
                :label="$capitalize($tc('model.form_lead_capital_coverings'))"
                :options="money"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              name="franchise"
              rules="required"
              v-slot="{ errors }"
            >
              <money-input
                v-model="form.franchise"
                type="text"
                :label="__('franquia', 'form_lead')"
                :options="percentage"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              name="franchise_value"
              rules="required"
              v-slot="{ errors }"
            >
              <money-input
                v-model="form.franchise_value"
                type="text"
                :label="
                  $capitalize($tc('model.form_lead_franchise_value_coverings'))
                "
                :options="money"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider name="franchise_min" v-slot="{ errors }">
              <money-input
                v-model="form.franchise_min"
                type="text"
                :label="
                  $capitalize(
                    $tc('model.form_lead_minimum_franchise_coverings')
                  )
                "
                :options="percentage"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider name="franchise_max" v-slot="{ errors }">
              <money-input
                v-model="form.franchise_max"
                type="text"
                :label="
                  $capitalize(
                    $tc('model.form_lead_maximum_franchise_coverings')
                  )
                "
                :options="percentage"
                :error-messages="errors"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" outlined color="primary">{{
              $capitalize($tc("model.form_lead_save_button"))
            }}</v-btn>
          </v-card-actions>
        </validation-observer>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import MoneyInput from "@/components/MoneyInput";
import i18n from "@/mixins/i18n";

export default {
  name: "coverage-form-dialog",
  components: { MoneyInput },
  mixins: [i18n],
  props: {
    value: Boolean,
    coverage: Object,
    errors: Array,
  },
  data: function () {
    return {
      money: {
        prefix: "€ ",
        suffix: "",
        precision: 2,
        length: 11,
        locale: "pt-PT",
      },
      percentage: {
        prefix: "",
        suffix: "%",
        precision: 2,
        length: 11,
        locale: "pt-PT",
      },
      form: {
        capital: 0,
        franchise_value: 0,
        franchise: 0,
        franchise_min: 0,
        franchise_max: 0,
      },
    };
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
    },
    resetForm() {
      this.$refs.form.reset();
      this.form = {
        capital: 0,
        franchise_value: 0,
        franchise: 0,
        franchise_min: 0,
        franchise_max: 0,
      };
    },
    treatNullFranchiseMinAndMax() {
      if (this.form.franchise_min === "") {
        this.form.franchise_min = 0;
      }
      if (this.form.franchise_max === "") {
        this.form.franchise_max = 0;
      }
    },
    getCoverage() {
      this.form = {
        ...this.coverage,
      };
    },
    async submit() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;
      this.treatNullFranchiseMinAndMax();
      this.$listeners.submit(this.form);
      this.handleClose();
    },
  },
  computed: {
    editMode() {
      return this.coverage != null;
    },
    dialogTitle() {
      if (this.editMode)
        return `${this.coverage?.product_description} ${this.form?.type_description}`;

      return this.$capitalize(this.$tc("model.form_lead_new_coverings"));
    },
  },
  watch: {
    value: function (value) {
      if (value && this.coverage) this.getCoverage();
      if (!value) this.resetForm();
    },
    errors: function (value) {
      if (value) {
        this.$refs.form.setErrors(value);
      }
    },
  },
};
</script>

<style></style>
